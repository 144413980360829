import React from 'react';
import { MenuItemRendererWrapper } from './index.css';
import MenuItem from '../MenuItem';

const defaultMenuItemTypes = {
	DEFAULT: MenuItem,
};

const MenuItemRenderer = ({ menuItemType, menuItemProps, menuItemCallback, customTypes = {} }) => {
	const menuItemTypes = { ...defaultMenuItemTypes, ...customTypes };
	const allowedTypes = Object.keys(menuItemTypes);

	if (!allowedTypes.includes(menuItemType)) {
		throw new Error(
			`MenuItemRenderer: Must Provide Valid menuItemType. Provided Type: ${menuItemType}. Allowed Types: ${allowedTypes}`
		);
	}

	const CurrentMenuItem = menuItemTypes[menuItemType];

	return (
		<MenuItemRendererWrapper className="MenuItemRendererWrapper">
			<CurrentMenuItem {...menuItemProps} menuItemCallback={menuItemCallback} />
		</MenuItemRendererWrapper>
	);
};

export default MenuItemRenderer;
