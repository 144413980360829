/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { AudioPlayerWrapper, VolUp, VolOff } from './index.css.js';

const AudioPlayer = ({
	src,
	fadeInInterval = 100,
	fadeOutInterval = 100,
	maxVolume = 1,
	fadeAmount = 0.05,
	iconSize = 60,
	offsetTop = 0,
	mobileOffsetTop = 0,
	style,
}) => {
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [userPlayed, setUserPlayed] = useState(false);
	const soundRef = useRef();
	const fadeRef = useRef();

	console.log(userPlayed);

	useEffect(() => {
		if (audioPlaying) {
			clearInterval(fadeRef.current);
			soundRef.current.play();
			fadeRef.current = setInterval(async () => {
				if (soundRef.current.volume < maxVolume - fadeAmount * 2) {
					soundRef.current.volume += fadeAmount;
				} else {
					soundRef.current.volume = maxVolume;
					clearInterval(fadeRef.current);
				}
			}, fadeInInterval);
		} else {
			clearInterval(fadeRef.current);
			fadeRef.current = setInterval(() => {
				if (soundRef.current.volume > fadeAmount) {
					soundRef.current.volume -= fadeAmount;
				} else {
					soundRef.current.pause();
					clearInterval(fadeRef.current);
				}
			}, fadeOutInterval);
		}
	}, [audioPlaying]); // eslint-diable-line

	const pauseAudio = async () => {
		setAudioPlaying(false);
	};

	const playAudio = async () => {
		setAudioPlaying(true);
	};

	const handleVolClick = async () => {
		if (soundRef.current) {
			if (soundRef.current.paused) {
				setUserPlayed(true);
				await playAudio();
			} else {
				setUserPlayed(false);
				await pauseAudio();
			}
		}
	};

	return (
		<AudioPlayerWrapper style={style} offsetTop={offsetTop} mobileOffsetTop={mobileOffsetTop}>
			<button type="button" onClick={handleVolClick}>
				{audioPlaying ? (
					<VolUp className="VolUp" size={iconSize} />
				) : (
					<VolOff className="VolOff" size={iconSize} />
				)}
				<audio loop ref={soundRef} hidden>
					<source src={src} type="audio/mpeg"></source>
				</audio>
			</button>
		</AudioPlayerWrapper>
	);
};

export default AudioPlayer;
