import React, { useEffect, useRef } from 'react';
import { VideoFlyThroughWrapper } from './index.css';

const VideoFlyThrough = ({ id, src, onEnd, videoProps, sourceProps, renderCallback }) => {
	const videoRef = useRef();

	useEffect(() => {
		if (renderCallback) {
			renderCallback({
				type: 'VIDEOFLYTHROUGH',
				action: 'init',
				ref: videoRef,
			});
		}
	}, []);

	return (
		<VideoFlyThroughWrapper
			className="VideoFlyThroughWrapper"
			ref={videoRef}
			id={id}
			playsInline
			disablePictureInPicture
			autoPlay
			muted
			controls
			onEnded={onEnd}
			{...videoProps}
		>
			<source src={src} type="video/mp4" {...sourceProps}></source>
		</VideoFlyThroughWrapper>
	);
};

export default VideoFlyThrough;
