import React from 'react';
import { ImageContentWrapper } from './index.css';

const ImageContent = ({ src, alt }) => {
	return (
		<ImageContentWrapper className="ImageContentWrapper">
			<img src={src} alt={alt} />
		</ImageContentWrapper>
	);
};

export default ImageContent;
