import styled from 'styled-components';

export const MultiMaskSliderContentWrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	.mask-slider-selector {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: center;
		overflow-x: auto;
		background-color: white;
		padding: 4px;
		&::-webkit-scrollbar {
			display: none;
		}
		img {
			cursor: pointer;
			width: 100px;
			border: 3px solid rgba(0, 0, 0, 0);
			&.active {
				border: 3px solid #FF9933;
			}
		}
	}
	.mask-slider-clinical-info {
		bottom: 0;
		color: white;
		font-size: 16px;
		position: relative;
		padding: 16px;
		background-image: linear-gradient(to right,#408745,#115f50);
		.small {
			font-size: 13px;
		}
	}
`;
