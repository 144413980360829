import React, { useState, useRef, useEffect } from 'react';
import { PdfContentWrapper } from './index.css';

const PdfContent = ({ viewerSrc, pdfSrc, name, contentCallback }) => {
	const [status, setStatus] = useState(false);
	const pdfRef = useRef(null);

	useEffect(() => {
		const pdfDownloadEvent = (e) => {
			if (e.target.className === 'toolbarButton download hiddenMediumView') {
				contentCallback({
					type: 'PDF',
					action: 'download',
					name,
				});
			}
		};

		const pdf = pdfRef.current?.contentWindow?.document?.querySelector('body');
		if (pdf?.querySelector('.download')) {
			pdf?.addEventListener('click', pdfDownloadEvent);
		}
		return () => {
			pdf?.removeEventListener('click', pdfDownloadEvent);
		};
	}, []);

	return (
		<PdfContentWrapper className="PdfContentWrapper">
			<iframe
				className="pdf-object"
				ref={pdfRef}
				src={`${viewerSrc}${pdfSrc}`}
				width="100%"
				onLoad={() => {
					setStatus(!status);
				}}
			>
				<p>
					Your browser does not support the in browser PDF viewer. Please download the PDF instead
					<a href={pdfSrc}>here</a>
				</p>
			</iframe>
		</PdfContentWrapper>
	);
};

export default PdfContent;
