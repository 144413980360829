import React from 'react';
import { DownloadsContentWrapper } from './index.css';

const DownloadsContent = ({ downloadItems, title, description, jobCode, contentCallback }) => {
	const downloadCards = downloadItems.map((item, idx) => (
		<a
			onClick={() => {
				if (contentCallback) {
					contentCallback({ type: 'DOWNLOADS', action: 'click', name: item.name });
				}
			}}
			href={item.asset}
			key={idx}
			download={item.name}
			target="_blank" rel="noreferrer"
		>
			<img src={item.image} alt={item.alt} />
		</a>
	));

	return (
		<DownloadsContentWrapper className="DownloadsContentWrapper">
			<div>
				<div className="title">{title}</div>
			</div>
			<div className="description">{description}</div>

			<div className="cards">{downloadCards}</div>

			<div className="code">{jobCode}</div>
		</DownloadsContentWrapper>
	);
};

export default DownloadsContent;
