import React from 'react';
import { OrientationWarningWrapper } from './index.css';
import { motion } from 'framer-motion';

const MobileLandscapeOverlay = ({ content, mobileLandscapeImage }) => {
	return (
		<>
			{/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (
				<>
					<OrientationWarningWrapper className="OrientationWarningWrapper">
						<div className="warning-wrap">
							<div>
								<motion.img
									style={{
										fontSize: '4rem',
										margin: ' 0 auto',
										maxWidth: '100%',
										height: '50vh',
									}}
									animate={{ rotate: -90 }}
									transition={{
										duration: 1,
										delay: 2.5,
										ease: 'easeOut',
										yoyo: Infinity,
										repeatDelay: 2.5,
									}}
									src={mobileLandscapeImage}
									alt="mobile-alt"
									width="100"
								/>
							</div>
							<div className="warning-text">{content}</div>
						</div>
					</OrientationWarningWrapper>
				</>
			)}
		</>
	);
};

export default MobileLandscapeOverlay;
