import * as React from "react"

function VolumeUpSolid(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 40 40"
      {...props}
    >
      <g data-name="vol">
        <circle cx={20} cy={20} r={20} fill="#2e2e2e" opacity={0.344} />
        <g data-name="vol" fill="#fff">
          <path
            data-name="Path 28783"
            d="M14.102 16.244H9.629v7.821h4.487l7.5 5.116v-18.07l-7.509 5.12z"
          />
          <path
            data-name="Path 28784"
            d="M27.001 20.587a5.043 5.043 0 00-2.512-4.348.845.845 0 00-.848 1.463 3.329 3.329 0 010 5.77.845.845 0 10.847 1.463 5.042 5.042 0 002.513-4.348z"
          />
          <path
            data-name="Path 28785"
            d="M26.369 12.418a1 1 0 10-1 1.734 7.426 7.426 0 010 12.871 1 1 0 101 1.734 9.43 9.43 0 000-16.339z"
          />
        </g>
      </g>
    </svg>
  )
}

export default VolumeUpSolid
