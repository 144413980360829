import React from 'react';
import { TimelineWrapper } from './index.css';

const Timeline = ({ videos, activeVideo, setActiveVideo }) => {
	const points = [];

	for (let i = 0; i < videos.length; i++) {
		points.push(
			<div
				key={i}
				className={`timeline ${activeVideo === i ? 'active' : ''}`}
				onClick={() => {
					if (activeVideo !== i) {
						setActiveVideo(i);
					}
				}}
			></div>
		);
	}

	console.log(points.length);

	return <TimelineWrapper className="TimelineWrapper">{(points.length === 1) ? '' : points}</TimelineWrapper>;
};

export default Timeline;
