import styled from 'styled-components';

export const OrientationWarningWrapper = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 9999999;
	text-align: center;
	margin: auto;
	background: rgba(0, 0, 0, 0.9);
	display: none;
	@media screen and (min-aspect-ratio: 13/9) and (max-width: 767px) {
		display: block;
	}

	.warning-wrap {
		color: white;
		position: absolute;
		padding-top: 0rem;
		padding-bottom: 1rem;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: 0 auto;
		width: calc(100% - 2rem);
		height: 50vh;
		.warning-text {
			font-weight: bold;
			font-size: 1em;
			padding-top: 0.5em;
			line-height: 1;
		}
	}
`;
