import { gaLogEvent } from '../ga';

export const gaContentDownload = ({ name, type }) => {
	console.log('gaContentDownload', { type, name });
	const event = {
		event: 'contentDownload',
		DLV_content_download_type: type,
		DLV_content_download_name: name,
	};
	gaLogEvent(event);
};
