import styled from 'styled-components';
import { MOBILE } from '../../../../constants/mediaQueries'

export const DownloadsContentWrapper = styled.div`
  background: #2e2940;
  display: flex;
  flex-direction: column;
	min-height: calc(100% - 2.5em);
  padding-top: 2.5em;

	@media ${MOBILE} {
		background: #000;
		padding-top: 0;
	}

	.title {
		background: #ff5454;
		color: white;
		font-size: 28px;
		font-weight: bold;
		padding: 0.2em 0 0.2em 4%;

		@media ${MOBILE} {
			padding: 0.2em 0 0.2em 5%;
			width: 100%;
		}
	}

	.description {
		color: white;
		font-size: 20px;
		padding: 0.75em 0 0.75em 4%;
	}

	.cards {
		display: flex;
		justify-content: space-around;
		padding: 1em 2em;

		a {
			margin: 0 0.5em;

			img {
				height: auto;
				max-height: 350px;
			}
		}

		@media ${MOBILE} {
			flex-flow: row wrap;
			padding: 0 2em 1em 2em;

			a {
				flex: 1 0 calc(50% - 1em);
				margin: 0.5em;
				max-width: 50%;
				-webkit-box-flex: 1;

				img  {
					max-width: 100%;
				}
			}
		}
	}

	.code {
		align-self: flex-end;
		bottom: 0;
		color: white;
		margin-right: 10px;
		margin-top: auto;
		right: 12px;

		@media ${MOBILE} {
			padding-right: 12px;
			padding-bottom: 12px;
			text-align: right;
		}
		}
`;
