import { gaLogEvent } from '../ga';

export const gaModalViewed = ({ name }) => {
	console.log('gaModalViewed', { name });
	const event = {
		event: 'modalViewed',
		DLV_modal_viewed_name: name,
	};
	gaLogEvent(event);
};
