import styled from 'styled-components';
import { MOBILE } from '../../../../constants/mediaQueries'

export const VideoContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;

  .video-title {
    color: #191E3D; 
    font-weight: bold; 
    font-size: 24px;
    left: 0.5em;
    margin-block: 0;
    position: absolute;
    text-align: left;
    top: 0.5em;
    width: 100%; 
    z-index: 999999;

    @media ${MOBILE} {
      width: auto;
    }
  }

  .video {
    background: #fff;
    cursor: pointer;
    height: 100%;
    margin: 0 auto;
    vertical-align: middle;
    width: 100%;
    :focus {
      outline: none;
    }
  }
`;
