import React, { useState, useEffect } from 'react';
import { MultiMaskSliderContentWrapper } from './index.css';
import ContentRenderer from '../../ContentRenderer';

const MultiMaskSliderContent = ({ maskSliders, contentCallback }) => {
	const [currentSlider, setCurrentSlider] = useState(0);

	useEffect(() => {
		contentCallback({
			type: 'MULTIMASKSLIDER',
			action: 'render',
			...maskSliders[currentSlider].slider,
		});
	}, [currentSlider]);

	return (
		<MultiMaskSliderContentWrapper className="MultiMaskSliderContentWrapper">
			<ContentRenderer
				contentType={'MASKSLIDER'}
				content={{ ...maskSliders[currentSlider].slider, text: undefined }}
				contentCallback={contentCallback}
			/>
			<div className="mask-slider-selector">
				{maskSliders.map((slider, index) => {
					return (
						<img
							key={index}
							src={slider.icon.src}
							alt={slider.icon.alt}
							className={`${index === currentSlider ? 'active' : ''}`}
							onClick={() => {
								setCurrentSlider(index);
							}}
						/>
					);
				})}
			</div>
			<div
				className="mask-slider-clinical-info"
				dangerouslySetInnerHTML={{ __html: maskSliders[currentSlider].slider.text }}
			/>
		</MultiMaskSliderContentWrapper>
	);
};

export default MultiMaskSliderContent;
