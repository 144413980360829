import React, { useRef, useEffect } from 'react';
import { VideoContentWrapper } from './index.css';

const VideoContent = ({ src, subs, poster, title, videoProps, sourceProps, contentCallback }) => {
	const videoRef = useRef(null);

	useEffect(() => {
		if (contentCallback) {
			contentCallback({
				type: 'VIDEO',
				action: 'init',
				videoRef,
				title,
			});
		}
	}, []);

	return (
		<VideoContentWrapper className="VideoContentWrapper">
			{title ? <h1 className="video-title">{title}</h1> : null}
			<video
				ref={videoRef}
				disablePictureInPicture
				crossOrigin="anonymous"
				controlsList="nodownload"
				controls
				autoPlay={false}
				muted={false}
				className="video"
				poster={poster}
				{...videoProps}
			>
				<source src={src} type="video/mp4" {...sourceProps} />
				{subs?.src ? <track label={subs.label} srcLang={subs.lang} kind="subtitles" src={subs.src} /> : null}
			</video>
		</VideoContentWrapper>
	);
};

export default VideoContent;
