import React from 'react';
import { LeavingDisclaimerWrapper } from './index.css';
import LargeBtn from './largeBtn';

const ExitModal = ({ disclaimerCopy, disclaimer, showModal, setShowModal }) => {
	const exitSite = () => {
		window.open(disclaimer?.href, '_blank');
	};
	return (
		<LeavingDisclaimerWrapper style={{ display: `${showModal ? 'block' : 'none'}` }}>
			<div className="outer">
				<div className="inner">
					<div className="exit-text">{disclaimerCopy.EXIT}</div>
					<div className="main-text">{disclaimerCopy.MAIN_COPY}</div>
					<div className="exit-buttons">
						<LargeBtn buttonContent={disclaimerCopy.BACK_BUTTON} onClick={() => setShowModal(null)} />
						<LargeBtn
							buttonContent={disclaimerCopy.CONTINUE_BUTTON}
							onClick={() => {
								exitSite();
								setShowModal();
							}}
						/>
					</div>
				</div>
			</div>
		</LeavingDisclaimerWrapper>
	);
};

export default ExitModal;
