export const getLocalStorage = (key) => {
	try {
		return localStorage.getItem(key);
	} catch (error) {
		return null;
	}
};

export const setLocalStorage = (key, value) => {
	try {
		localStorage.setItem(key, value);
	} catch (error) {} // eslint-disable-line
};
