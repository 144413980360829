import React, { useState, useRef, useEffect } from 'react';
import { VideoWithTimelineContentWrapper } from './index.css';

import Timeline from './Timeline';

const Video = ({ src, subs, poster, title, videoProps, sourceProps, videoRef }) => {
	return (
		<>
			<div className="video-header">
				<span className="title" dangerouslySetInnerHTML={{ __html: title }} />
			</div>
			<div className="video-wrapper">
				<video
					disablePictureInPicture
					crossOrigin="anonymous"
					ref={videoRef}
					controlsList="nodownload"
					controls
					autoPlay={false}
					poster={poster}
					{...videoProps}
				>
					<source src={src} type="video/mp4" {...sourceProps} />
					{subs?.src ? (
						<track label={subs.label} srcLang={subs.lang} kind="subtitles" src={subs.src} />
					) : null}
				</video>
			</div>
		</>
	);
};

const VideoWithTimelineContent = ({ videos, timelineLabel, contentCallback }) => {
	const [activeVideo, setActiveVideo] = useState(0);
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			if (videos[activeVideo].src !== videoRef.current.currentSrc) {
				videoRef.current.src = videos[activeVideo].src;
				videoRef.current.poster = videos[activeVideo]?.poster;
				if (videos[activeVideo]?.startAt) {
					videoRef.current.currentTime = videos[activeVideo]?.startAt;
				}
				contentCallback({
					type: 'VIDEOTIMELINE',
					action: 'change',
					videoRef,
					title: videos[activeVideo].title,
				});
			} else {
				contentCallback({
					type: 'VIDEOTIMELINE',
					action: 'init',
					videoRef,
					title: videos[activeVideo].title,
				});
			}
		}
	}, [activeVideo]);

	return (
		<VideoWithTimelineContentWrapper className="VideoWithTimelineContentWrapper">
			<Video videoRef={videoRef} {...videos[activeVideo]} />
			<div className="timeline-container">
				<div className="title">{timelineLabel}</div>
				<Timeline videos={videos} activeVideo={activeVideo} setActiveVideo={setActiveVideo} />
			</div>
		</VideoWithTimelineContentWrapper>
	);
};

export default VideoWithTimelineContent;
