import { useMediaQuery } from 'react-responsive';
import { MOBILE, TABLET, LAPTOP, DESKTOP } from '../../constants/mediaQueries';

const sizes = {
	mobile: MOBILE,
	tablet: TABLET,
	laptop: LAPTOP,
	desktop: DESKTOP,
};

const useScreenSize = (size) => {
	if (Array.isArray(size)) {
		const queries = [];
		size.forEach((s) => {
			queries.push(useMediaQuery({ query: sizes[s] }));
		});

		let match = false;
		queries.forEach((query) => {
			if (query) {
				match = true;
			}
		});
		console.log('match is', match);
		return match;
	} else {
		return useMediaQuery({
			query: sizes[size],
		});
	}
};

export default useScreenSize;
