import React from 'react';
import { MenuItemSpacer, MenuItemWrapper } from './index.css';

const MenuItem = ({ action, active, menuItemCallback, className, text, src, alt, externalLink }) => {
	return (
		<MenuItemSpacer className="MenuItemSpacer">
			<MenuItemWrapper
				className={`MenuItemWrapper ${active ? 'active' : ''} ${className ? className : ''}`}
				onClick={() => {
					menuItemCallback({
						itemType: 'MenuItem',
						actionType: 'click',
						action,
					});
				}}
			>
				{src ? <img src={src} alt={alt} className="menuItemImage" /> : externalLink ? <p><a href={externalLink} target="_blank" rel="noreferrer">{text}</a></p> : <p>{text}</p> }
			</MenuItemWrapper>
		</MenuItemSpacer>
	);
};

export default MenuItem;
