import { gaLogEvent } from '../ga';

export const gaContentViewed = ({ type, name }) => {
	console.log('gaContentViewed', { type, name });

	const event = {
		event: 'contentViewed',
		DLV_content_viewed_type: type,
		DLV_content_viewed_name: name,
	};
	gaLogEvent(event);
};
