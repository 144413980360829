import styled from 'styled-components';
import {MOBILE} from '../../../constants/mediaQueries';

export const MenuItemSpacer = styled.div`
	@media ${MOBILE} {
		background: #CCC;
		padding: 4px 4px 4px 0;
	}
`

export const MenuItemWrapper = styled.div`
	align-items: center;
	background: ${(props) => props.theme.navigation.navBgPrimary};
	border-left: 5px solid ${(props) => props.theme.navigation.navTabInactive};
	cursor: pointer;
	display: flex;
	font-family: Arial, sans-serif;	
	min-height: 40px;
	margin-top: 4px;
	padding: 0;
	@media ${MOBILE} {
		background: #ff5454;
		border-bottom: 4px solid #ff5454;
		margin-top: 0;
		padding: 4px 0 0 0;
		white-space: nowrap;
		width: 100%;
	}
	p {
		color: ${(props) => props.theme.navigation.navTextPrimary};
		font-weight: bold;
		font-size: 14px;
		margin-block-start: 0;
		margin-block-end: 0;
		padding: 8px 12px;
		@media ${MOBILE} {
			padding: 8px 12px;
		}
	}
	&:hover {
		background-color: ${(props) => props.theme.navigation.navBgPrimaryHover};
		border-left: 5px solid ${(props) => props.theme.navigation.navBgPrimaryHover};
		transition: background-color 0.5s;
		@media ${MOBILE} {
			background: white;
		}
		p {
			color: white;
			@media ${MOBILE} {
				color: #2e2940;
			}
		}
	}	
	&:not(.dropdown) {
		background-image: none;
	}
	&.active:not(.dropdown) {
		background-color: ${(props) => props.theme.navigation.navBgPrimaryActive};
		background-image: none;
		border-left: 5px solid ${(props) => props.theme.navigation.navTabActive};
		@media ${MOBILE} {
			background-color: white;
			border-bottom: 4px solid #2e2940;
		}
		p {
			color: ${(props) => props.theme.navigation.navTextSecondary};
			@media ${MOBILE} {
				color: #2e2940;
			}
		}
	}
	&.dropdown-level-1, &.dropdown-level-2, &.dropdown-level-3 {
		background-color: ${(props) => props.theme.navigation.navBgPrimaryActive};
		border-left: 5px solid ${(props) => props.theme.navigation.navTabPrimaryActive};
		p {
			color: ${(props) => props.theme.navigation.navTextTertiary};
		}
	}
	&.active {
		background-color: ${(props) => props.theme.navigation.navBgPrimary};
		background-size: 15px 9px;
		transition: background-color 0.5s;
		@media ${MOBILE} {
			background-color: white;
			border-bottom: 4px solid #2e2940;
		}
		p {
			color: ${(props) => props.theme.navigation.navTextPrimary};
			@media ${MOBILE} {
				color: #2e2940;
			}
		}
	}
	.menuItemImage {
		width: calc(100% - 30px);
		padding: 15px;
	}
`;
