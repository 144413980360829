import styled from 'styled-components';

export const TimelineWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 100px;
	justify-content: flex-end;
	padding-right: 80px;
	& + &:last-of-type {
		background: red !important;
	}
	@media screen and (max-width: 768px) {
		align-items: flex-start;
		height: 80px;
		justify-content: center;
		text-align: center;
		padding: 20px 40px 0 40px;
		width: 100%;
	}

	.timeline {
		background: #796e65;
		height: 4px;
		position: relative;
		width: 130px;

		&.active {
			&:before {
				background: #796e65;
				left: -12px;
				height: 25px;
				top: -12px;
				width: 25px;
			}
			&:after {
				color: #796e65;
				font-weight: bold;
				left: -8px;
				top: -8px;
			}
		}
		&:before {
			background: ${(props) => props.theme.videoWithTimeline.videoWithTimelineBgPrimary};
			border-radius: 50%;
			content: '';
			cursor: pointer;
			height: 15px;
			left: -8px;
			position: absolute;
			top: -7px;
			width: 15px;
		}
		&:after {
			content: attr(data-text);
			color: ${(props) => props.theme.videoWithTimeline.videoWithTimelineBgPrimary};
			display: flex;
			font-size: 14px;
			left: -8px;
			justify-content: center;
			transform: translate(-50%, 20px);
			top: -8px;
		}
		&:last-of-type {
			height: 0;
			top: 0;
			width: 0;
		}
	}
`;
