import React, { useState, useEffect } from 'react';
import { DropdownContentWrapper } from './index.css';
import ContentRenderer from '../../ContentRenderer';
import MenuItemRenderer from '../../../Menu/MenuItemRenderer';

const DropdownContent = ({ renderId, dropdownMenu, dropdownContent, contentCallback }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	useEffect(() => {
		if (dropdownOpen) {
			setDropdownOpen(false);
		}
	}, [renderId]);

	useEffect(() => {
		if (dropdownOpen) {
			contentCallback({
				type: 'DROPDOWN',
				action: 'click',
				open: dropdownOpen,
				...dropdownContent,
			});
		}
	}, [dropdownOpen]);

	return (
		<DropdownContentWrapper className="DropdownContentWrapper">
			<MenuItemRenderer
				menuItemCallback={(cb) => {
					if (cb.actionType === 'click') {
						setDropdownOpen(!dropdownOpen);
					}
				}}
				menuItemType={dropdownMenu.menuItemType}
				menuItemProps={{ active: dropdownOpen, className: 'dropdown', ...dropdownMenu.menuItemProps }}
			/>
			{dropdownOpen ? <ContentRenderer contentCallback={contentCallback} {...dropdownContent} /> : null}
		</DropdownContentWrapper>
	);
};

export default DropdownContent;
