import styled from 'styled-components';
import { MOBILE } from '../../../../constants/mediaQueries';

export const MaskSliderWrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	flex-direction: column;
	height: 100%;

	p {
		user-select: none;
	}

	.mask-image {
		background-repeat: no-repeat;
		background-size: auto 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		width: 50%;
		&.left {
			background-position: 165px center;
			left: 0;
			right: auto;
		}
		&.right {
			background-position: right 165px center;
			left: auto;
			right: 0;
		}
	}

	.slider-wrapper {
		cursor: grab;
		height: 1000px;
		overflow: hidden;
		position: relative;
		width: 100%;
		.slider {
			position: absolute;
		}
	}

	.content-header {
		align-items: center;
		background: white;
		clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
		color: ${(props) => props.theme.maskSlider.maskSliderTextPrimary};
		display: flex;
		flex-direction: row;
		font-size: 20px;
		font-weight: bold;
		line-height: 1.1;
		min-height: 60px;
		min-width: 200px;
		max-width: 420px;
		padding: 8px 50px 8px 15px;
		position: absolute;
		width: fit-content;
		z-index: 55;

		@media ${MOBILE} {
			font-size: 16px;
			min-height: 0;
			padding: 8px 32px 8px 16px;
		}
	}

	.mask-slider-container {
		display: block;
		margin: 0;
		overflow: hidden;
		position: relative;
		width: 100%;

		.inner-container {
			bottom: 0;
			left: -999px;
			margin: 0 auto;
			position: absolute;
			right: -999px;
			top: 0;
			width: 1000px;
		}
	}

	.mask-slider {
		cursor: grab;
		height: 1400px;
		position: absolute;
		pointer-events: none;
		transform-origin: -50% -50%;
		transform: scale(0.5);
		width: 610px;
	}

	.mask-slider-before {
		height: 80px;
		left: -140px;
		position: absolute;
		top: -29px;
		width: 130px;
		.title {
			color: ${(props) => props.theme.maskSlider.maskSliderTextPrimary};
			font-weight: bold;
			font-size: 1.2em;
			height: 25px;
			margin: 0;
		}
		.text {
			color: #776c64;
			font-weight: bold;
			line-height: 1.1;
			margin: 0;
		}
	}

	.mask-slider-after {
		height: 80px;
		left: 40px;
		position: absolute;
		top: -29px;
		width: 130px;
		.title {
			color: #fff;
			font-weight: bold;
			font-size: 1.2em;
			height: 25px;
			margin: 0;
		}
		.text {
			color: #fff;
			font-weight: bold;
			line-height: 1.1;
			margin: 0;
		}
	}

	.mask-slider-clinical-info {
		bottom: 0;
		color: white;
		font-size: 16px;
		position: relative;
		padding: 8px;

		.small {
			font-size: 13px;
		}
	}
`;
