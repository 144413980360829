import { makeStore, createReducerFromObject } from '../../utils';
import { UI_ACTIONS, uiObjectReducer } from './uiReducer';

const uiInitialState = {
	currentAction: '',
	isReady: false,
	showPopup: false,
};

const [UiProvider, useUiDispatch, useUiState] = makeStore(createReducerFromObject(uiObjectReducer), uiInitialState);

function useUiActions() {
	const dispatch = useUiDispatch();

	const setIsReady = (isReady) => {
		dispatch({
			type: UI_ACTIONS.SET_IS_READY,
			isReady,
		});
	};

	const setShowPopup = (showPopup) => {
		dispatch({
			type: UI_ACTIONS.SET_SHOW_POPUP,
			showPopup,
		});
	};

	const setCurrentAction = (currentAction) => {
		dispatch({
			type: UI_ACTIONS.SET_CURRENT_ACTION,
			currentAction,
		});
	};

	return { setIsReady, setShowPopup, setCurrentAction };
}

export { UiProvider, useUiActions, useUiState };
