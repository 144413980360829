import { gaLogEvent } from '../ga';

const logEvent = ({ name, percentage }) => {
	console.log('gaPercentageVideoViewed logEvent', { name, percentage });
	const event = {
		event: 'percentageVideoViewed',
		DLV_content_video_name: name,
		DLV_content_video_percentage: percentage,
	};
	return gaLogEvent(event);
};

export const gaPercentageVideoViewed = (data) => {
	const { hitRate, video, name } = data;

	const percentageHitRate = hitRate ? hitRate : 4;

	let intervalInstance;

	const interval = function (cb) {
		intervalInstance = setInterval(cb, 2000);
	};

	const playVideo = () => {
		const timePoint = video.duration / percentageHitRate;
		const conditions = Array.apply(null, Array(percentageHitRate)).map(function (condition, i) {
			const min = timePoint * (i + 1);
			const max = timePoint * (i + 2);

			return {
				min: min,
				max: max,
				response: (100 / percentageHitRate) * (i + 1),
				valid: min > video.currentTime,
			};
		});

		const generatePercentageViewed = () => {
			conditions.forEach(function (condition) {
				if (video.currentTime >= condition.min && video.currentTime < condition.max && condition.valid) {
					condition.valid = false;
					logEvent({ name, percentage: condition.response });
				}
			});
		};
		interval(generatePercentageViewed);
	};

	const endVideo = () => {
		clearInterval(intervalInstance);
		logEvent({ name, percentage: 100 });
	};

	const pauseVideo = () => {
		clearInterval(intervalInstance);
	};

	video.addEventListener('play', playVideo);
	video.addEventListener('ended', endVideo);
	video.addEventListener('pause', pauseVideo);

	return () => {
		clearInterval(intervalInstance);
		video.removeEventListener('play', playVideo);
		video.removeEventListener('ended', endVideo);
		video.removeEventListener('pause', pauseVideo);
	};
};
