import React from 'react';
import styled from 'styled-components';

const LargeButtonWrapper = styled.button`
	background: #049b77;
	border-radius: 23px;
	border: none;
	box-shadow: 0px 4px 4px 0px #2d3742;
	cursor: pointer;
	color: white;
	min-height: 46px;
	font-size: 20px;
	outline: none;
	padding: 0 3rem;
	cursor: pointer;
	:hover {
		transform: scale(1.02);
		transition: 0.2s;
	}
	:disabled {
		cursor: not-allowed;
		opacity: 0.3;
		:hover {
			transform: none;
		}
	}
`;

const LargeButton = ({ buttonContent, onClick, disabled }) => {
	return (
		<LargeButtonWrapper className="LargeButtonWrapper" onClick={onClick} disabled={disabled || false}>
			{buttonContent}
		</LargeButtonWrapper>
	);
};

export default LargeButton;
