import React from 'react';
import { MaskSliderWrapper } from './index.css';
import { useMaskSlider } from '../../../../hooks/component-hooks';

const MaskSliderContent = ({ before, after, title, text, sliderImg, defaultWidth, defaultHeight }) => {
	const { maskSliderRef, size } = useMaskSlider({ defaultWidth, defaultHeight });

	const MaskImage = ({ src, isLeft }) => {
		return (
			<div
				id={`MaskImage-${isLeft ? 'Left' : 'Right'}`}
				className={`mask-image ${isLeft ? 'left' : 'right'}`}
				style={{
					backgroundImage: `url(${src})`,
				}}
			/>
		);
	};

	const Slider = () => {
		return (
			<div className="slider-wrapper">
				<div
					id="slider"
					className="slider"
					style={{
						left: size.w * 0.5,
						top: `${size.h * 0.5}px`,
					}}
				>
					<div className="mask-slider" style={{ backgroundImage: `url(${sliderImg})` }} />
					<div className="mask-slider-before">
						<p className="title">{before.title}</p>
						<p className="text" dangerouslySetInnerHTML={{ __html: before.text }} />
					</div>
					<div className="mask-slider-after">
						<p className="title">{after.title}</p>
						<p className="text" dangerouslySetInnerHTML={{ __html: after.text }} />
					</div>
				</div>
			</div>
		);
	};

	return (
		<MaskSliderWrapper className="MaskSliderWrapper">
			<div
				className="mask-slider-container"
				style={{
					height: `${size.h}px`,
				}}
			>
				<div className="content-header" dangerouslySetInnerHTML={{ __html: title }} />
				<div
					ref={maskSliderRef}
					className="inner-container"
					style={{
						height: `${size.h}px`,
					}}
				>
					<MaskImage src={before.src} isLeft={true} />
					<MaskImage src={after.src} isLeft={false} />
					<Slider />
				</div>
			</div>

			{text ? <div className="mask-slider-clinical-info" dangerouslySetInnerHTML={{ __html: text }} /> : null}
		</MaskSliderWrapper>
	);
};

export default MaskSliderContent;
