import styled from 'styled-components';
import { MOBILE } from '../../../../constants/mediaQueries'

export const VideoWithTimelineContentWrapper = styled.div`
	background: white;
	.video-header {
		align-items: center;
		/* background-image: linear-gradient(to right, #11b07e, 80%, #90cc57); */
		background-color: ${(props) => props.theme.videoWithTimeline.videoWithTimelineBgPrimary};
		color: white;
		clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
		display: flex;
		flex-direction: row;
		font-weight: bold;
		font-size: 20px;
		min-height: 70px;
		max-height: 70px;
		margin-bottom: 5px;
		min-width: 65%;
		padding: 0 84px 0 24px;
		width: fit-content;
		top: 0;
		left: 0;

		@media ${MOBILE} {
			padding: 0 50px 0 24px;
		}

		&.videoHeader-transparent {
			background-image: linear-gradient(#808080, 50%, transparent);
			clip-path: none;
			font-size: 16px;
			min-height: 80px;
			max-height: 80px;
			width: 100%;

			&.withJobNumber {
				display: flex;
				justify-content: space-between;
				padding: 24px;
			}
		}
	}
	.video-wrapper {
		height: 100%;
		overflow: hidden;
		width: 100%;
		video {
			background: #fff;
			cursor: pointer;
			display: flex;
			max-height: 100%;
			height: auto;
			justify-content: center;
			margin: 0 auto;
			vertical-align: middle;
			width: 60%;
			margin-top: 20px;
			@media ${MOBILE} {
				width: 90%;
			}
			:focus {
				outline: none;
			}

			&.fullscreen {
				height: 100%;
				width: 100%;
			}
		}
	}

	.timeline-container {
		align-items: center;
		display: flex;
		justify-content: space-around;
	}
`;
