import TagManager from 'react-gtm-module';

let _iframeHostedUrl = null;

export const gaLogEvent = (event) => {
	_iframeHostedUrl
		? window.parent.postMessage(JSON.stringify(event), _iframeHostedUrl)
		: TagManager.dataLayer({ dataLayer: event });
};

export const gaInitalise = ({ gtmId, iframeHostedUrl = null }) => {
	_iframeHostedUrl = iframeHostedUrl;

	if (gtmId) {
		const tagManagerArgs = {
			gtmId,
		};
		TagManager.initialize(tagManagerArgs);
	}
};
