import { useEffect, useState, useRef } from 'react';

export default function useMaskSlider({ defaultWidth = 1100, defaultHeight = 381 } = {}) {
	const maskSliderRef = useRef(null);

	const [size, setSize] = useState({ w: 0, h: 0 });

	useEffect(() => {
		let isDown = false;

		const relativeCoords = ({ x, y }) => {
			const bounds = maskSliderRef.current.getBoundingClientRect();

			return {
				x: Math.round(Math.max(0, Math.min(bounds.width, x - bounds.left))),
				y: Math.round(Math.max(0, Math.min(bounds.height, y - bounds.top))),
			};
		};

		const touchMove = (e) => {
			const coords = relativeCoords({ x: e.touches[0].pageX, y: e.touches[0].pageY });

			const slider = maskSliderRef.current.querySelector('#slider');
			const maskLeft = maskSliderRef.current.querySelector('#MaskImage-Left');
			const maskRight = maskSliderRef.current.querySelector('#MaskImage-Right');
			slider.style.left = `${coords.x}px`;
			maskLeft.style.width = `${coords.x}px`;
			maskRight.style.width = `calc(100% - ${coords.x}px)`;
		};

		const mouseMove = (e) => {
			if (isDown) {
				const coords = relativeCoords({ x: e.clientX, y: e.clientY });

				const slider = maskSliderRef.current.querySelector('#slider');
				const maskLeft = maskSliderRef.current.querySelector('#MaskImage-Left');
				const maskRight = maskSliderRef.current.querySelector('#MaskImage-Right');
				slider.style.left = `${coords.x}px`;
				maskLeft.style.width = `${coords.x}px`;
				maskRight.style.width = `calc(100% - ${coords.x}px)`;
			}
		};

		const setDown = () => (isDown = true);
		const setUp = () => (isDown = false);

		if (maskSliderRef.current) {
			maskSliderRef.current.addEventListener('touchstart', touchMove);
			maskSliderRef.current.addEventListener('touchmove', touchMove);

			maskSliderRef.current.addEventListener('mousedown', setDown);
			maskSliderRef.current.addEventListener('mouseup', setUp);
			maskSliderRef.current.addEventListener('mousemove', mouseMove);
		}

		return () => {
			if (maskSliderRef.current) {
				maskSliderRef.current.removeEventListener('touchstart', touchMove);
				maskSliderRef.current.removeEventListener('touchmove', touchMove);

				maskSliderRef.current.removeEventListener('mousedown', setDown);
				maskSliderRef.current.removeEventListener('mouseup', setUp);
				maskSliderRef.current.removeEventListener('mousemove', mouseMove);
			}
		};
	}, [maskSliderRef.current]);

	useEffect(() => {
		const computeSize = () => {
			const rect = maskSliderRef.current.getBoundingClientRect();
			const s = rect.width / defaultWidth;
			setSize({ w: defaultWidth * s, h: defaultHeight });
		};
		window.addEventListener('resize', computeSize);
		computeSize();
		return () => {
			window.removeEventListener('resize', computeSize);
		};
	}, [maskSliderRef]);

	return {
		size,
		maskSliderRef,
	};
}
