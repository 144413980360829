import { MENU_ACTIONS } from './menuReducer';
export { MENU_ACTIONS };

export const remainOpen = {
	[MENU_ACTIONS.selectItem]: (state, action) => {
		return {
			...state,
			selectedItem: action.selectedItem,
			open: true,
		};
	},
};

export const highlightOne = {
	[MENU_ACTIONS.toggleHighlightItem]: (state, action) => {
		return {
			...state,
			highlightedItems: [action.highlightItem],
		};
	},
};

const searchMenu = (menuItems, search) => {
	for (let i = 0; i < menuItems.length; i++) {
		if (menuItems[i].action === search) {
			return menuItems[i];
		} else if (menuItems[i].children) {
			const found = searchMenu(menuItems[i].children, search);
			if (found) {
				return found;
			}
		}
	}
};

const findTopParent = (menuItems, itemAction) => {
	let parent;
	for (let i = 0; i < menuItems.length; i++) {
		parent = menuItems[i];
		if (menuItems[i].children) {
			const found = searchMenu(menuItems[i].children, itemAction);
			if (found) return parent;
		}
	}
};

/**
 * Gets all actions from item and its children
 */
const getAllItemActions = (item, actions = []) => {
	actions.push(item.action);
	if (item.children) {
		item.children.forEach((child) => {
			getAllItemActions(child, actions);
		});
	}
	return actions;
};

const treeHasItem = (parent, item) => {
	if (JSON.stringify(parent).indexOf(`"action":"${item}"`) > -1) {
		return true;
	}
};

const allowedHierarchy = (topParent, item, currentHierarchy = []) => {
	if (treeHasItem(topParent, item)) {
		currentHierarchy.push(topParent.action);
		if (topParent.children) {
			topParent.children.forEach((child) => {
				allowedHierarchy(child, item, currentHierarchy);
			});
		}
	}
	return currentHierarchy;
};

export const highlightOneAndOneChild = {
	[MENU_ACTIONS.toggleHighlightItem]: (state, action) => {
		if (state.menuItems.length < 1) {
			throw new Error('You must declare menuItems in initialState for highlightOneAndChildren');
		}
		const selectedItem = searchMenu(state.menuItems, action.highlightItem);
		const selectedItemTopParent = findTopParent(state.menuItems, action.highlightItem);
		const allowedActions = allowedHierarchy(
			selectedItemTopParent ? selectedItemTopParent : selectedItem,
			action.highlightItem
		);

		if (state.highlightedItems.includes(action.highlightItem)) {
			return {
				...state,
				highlightedItems: [...state.highlightedItems.filter((item) => !allowedActions.includes(item))],
			};
		} else {
			return {
				...state,
				highlightedItems: [
					...state.highlightedItems.filter((item) => allowedActions.includes(item)),
					action.highlightItem,
				],
			};
		}
	},
};

export const highlightOneAndChildren = {
	[MENU_ACTIONS.toggleHighlightItem]: (state, action) => {
		if (state.menuItems.length < 1) {
			throw new Error('You must declare menuItems in initialState for highlightOneAndChildren');
		}
		const selectedItem = searchMenu(state.menuItems, action.highlightItem);
		const selectedItemTopParent = findTopParent(state.menuItems, action.highlightItem);
		const allowedActions = getAllItemActions(selectedItemTopParent ? selectedItemTopParent : selectedItem);

		if (state.highlightedItems.includes(action.highlightItem)) {
			return {
				...state,
				highlightedItems: [...state.highlightedItems.filter((item) => !allowedActions.includes(item))],
			};
		} else {
			return {
				...state,
				highlightedItems: [
					...state.highlightedItems.filter((item) => allowedActions.includes(item)),
					action.highlightItem,
				],
			};
		}
	},
};

export const clearHighlightOnToggle = {
	[MENU_ACTIONS.toggle]: (state, action) => {
		return {
			...state,
			highlightedItems: [],
			open: !state.open,
		};
	},
};
