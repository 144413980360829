import * as React from "react"

function VolumeMuteSolid(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 40 40"
      {...props}
    >
      <g data-name="vol">
        <circle cx={20} cy={20} r={20} opacity={0.344} />
        <g data-name="vol off" fill="#fff">
          <path
            data-name="Path 28786"
            d="M11.112 16.426v7.821h4.487l7.5 5.116v-3.042l-9.9-9.895z"
          />
          <path
            data-name="Path 28787"
            d="M28.438 26.299l-5.344-5.344v-9.661l-5.745 3.917-3.749-3.75a1.194 1.194 0 10-1.688 1.688L26.75 27.985a1.194 1.194 0 001.688-1.689z"
          />
        </g>
      </g>
    </svg>
  )
}

export default VolumeMuteSolid