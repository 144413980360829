import styled from 'styled-components';
import volUp from '../../assets/icons/VolumeUpSolid';
import volOff from '../../assets/icons/VolumeMuteSolid';

export const AudioPlayerWrapper = styled.div`
	position: absolute;
	top: ${(props) => props.offsetTop}px;
	right: 0;
	margin: 5px;
	@media screen and (max-width: 768px) {
		top: ${(props) => props.mobileOffsetTop}px;
	}
`;

export const VolUp = styled.div`
	width: ${(props) => (props.size ? props.size : 100)}px;
	height: ${(props) => (props.size ? props.size : 100)}px;
	background: url(${volUp});
	background-size: 100% 100%;
`;

export const VolOff = styled.div`
	width: ${(props) => (props.size ? props.size : 100)}px;
	height: ${(props) => (props.size ? props.size : 100)}px;
	background: url(${volOff});
	background-size: 100% 100%;
`;
