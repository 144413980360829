import React from 'react';
import { ModalWrapper } from './index.css';
import SvgIconClose from '../../../assets/icons/icon-close.jsx';

const Modal = ({ children, open, setOpen, banner }) => {
	return (
		<ModalWrapper className={`${open ? 'open' : ''} ${banner ? 'banner' : ''} ModalWrapper`}>
			<div className="content-wrapper">
				<SvgIconClose
					className="close-icon"
					onClick={() => {
						setOpen(false);
					}}
				/>
				{children}
			</div>
		</ModalWrapper>
	);
};

export default Modal;
