import styled from 'styled-components';
import { MOBILE } from '../../../constants/mediaQueries'

export const ModalWrapper = styled.div`
	align-items: center;
	animation: 0.5s linear 0s 1 normal none running;
	background: rgba(0, 0, 0, 0.7);
	flex-direction: column;
	height: 100%;
	justify-content: center;
	position: absolute;
	width: 100%;
	z-index: 11;
	&.open {
		display: flex;
	}
	.content-wrapper {
		height: 100%;
    max-width: 1300px;
    max-height: 550px;
    padding: 15px;
    position: relative;
		width: calc(100% - 30px);

		@media ${MOBILE} {
			background: white;
			max-height: none;
			width: 100%;
			padding: 0;
		}
	}
	.close-icon {
		color: #F5F5F5;
		height: 34px;
		position: absolute;
		right: 15px;
		top: 10px;
		width: 34px;
		z-index: 55;
		@media ${MOBILE} {
			background: black;
		}
		&:hover {
			cursor: pointer;
			color: #CCC;
		}
	}
`;
