import React, { useEffect } from 'react';
import { usePrevious } from '../../../hooks/util-hooks';
import { ContentRendererWrapper } from './index.css';

import ImageContent from '../ContentTypes/ImageContent';
import VideoContent from '../ContentTypes/VideoContent';
import MaskSliderContent from '../ContentTypes/MaskSliderContent';
import MultiMaskSliderContent from '../ContentTypes/MultiMaskSliderContent';
import DownloadsContent from '../ContentTypes/DownloadsContent';
import PdfContent from '../ContentTypes/PdfContent';
import VideoWithTimelineContent from '../ContentTypes/VideoWithTimelineContent';
import DropdownContent from '../ContentTypes/DropdownContent';
import MultiContent from '../ContentTypes/MultiContent';

const defaultContentTypes = {
	IMAGE: ImageContent,
	VIDEO: VideoContent,
	MASKSLIDER: MaskSliderContent,
	MULTIMASKSLIDER: MultiMaskSliderContent,
	DOWNLOADS: DownloadsContent,
	PDF: PdfContent,
	VIDEOTIMELINE: VideoWithTimelineContent,
	DROPDOWN: DropdownContent,
	MULTI: MultiContent,
};

const ContentRenderer = ({ contentType, content, renderCallback, contentCallback, customTypes = {}, scrollRef }) => {
	const previous = usePrevious({ contentType, content });

	const contentTypes = { ...defaultContentTypes, ...customTypes };
	const allowedTypes = Object.keys(contentTypes);

	if (!allowedTypes.includes(contentType)) {
		throw new Error(
			`ContentRenderer: Must Provide Valid contentType. Provided Type: ${contentType}. Allowed Types: ${allowedTypes}`
		);
	}

	const CurrentContent = contentTypes[contentType];

	useEffect(() => {
		const previousContent = JSON.stringify(previous?.content);
		const currentContent = JSON.stringify(content);

		if (previousContent !== currentContent) {
			if (renderCallback) {
				renderCallback({ contentType, content, scrollRef });
			}
		}
	}, [contentType, content]);

	return (
		<ContentRendererWrapper className="ContentRendererWrapper">
			<CurrentContent {...content} contentCallback={contentCallback} renderCallback={renderCallback} />
		</ContentRendererWrapper>
	);
};

export default ContentRenderer;
