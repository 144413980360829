import React from 'react';
import styled from 'styled-components';

const Btn = styled.button`
  background-color: ${(props) => props.theme.buttons.buttonBgSecondary};
  border: 1px solid ${(props) => props.theme.buttons.buttonBorderPrimary};
  color: ${(props) => props.theme.buttons.buttonTextPrimary};
  opacity: 0.75;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: initial;
  line-height: normal;
  min-width: 80px;
  height: 40px;
  transition: background-color 0.25s ease-in-out;
  outline: none;
  &:hover {
    background-color: ${(props) => props.theme.buttons.buttonBgPrimary};
    cursor: pointer;
    color: ${(props) => props.theme.buttons.buttonTextSecondary};
    border: 1px solid ${(props) => props.theme.buttons.buttonBorderSecondary};
    outline: none;
  }
  &:active {
    outline: none;
  }
`;

const ButtonReverse = ({ text, handleSubmit, isDisabled, icon }) => (
	<Btn className="ButtonReverse" onClick={handleSubmit} disabled={isDisabled}>
		{text}
		{icon}
	</Btn>
);

export default ButtonReverse;
