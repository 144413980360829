export const MENU_ACTIONS = {
	setMenuItems: 'SET_MENU_ITEMS',
	toggle: 'TOGGLE',
	open: 'OPEN',
	close: 'CLOSE',
	selectItem: 'SELECT_ITEM',
	toggleHighlightItem: 'TOGGLE_HIGHLIGHT_ITEM',
};

export const defaultMenuInitialState = {
	menuItems: [],
	open: false,
	selectedItem: '',
	highlightedItems: [],
};

export const defaultMenuObjectReducer = {
	[MENU_ACTIONS.setMenuItems]: (state, action) => {
		return {
			...state,
			menuItems: action.menuItems,
		};
	},
	[MENU_ACTIONS.toggle]: (state, action) => {
		return {
			...state,
			open: !state.open,
		};
	},
	[MENU_ACTIONS.open]: (state, action) => {
		return {
			...state,
			open: true,
		};
	},
	[MENU_ACTIONS.close]: (state, action) => {
		return {
			...state,
			open: false,
		};
	},
	[MENU_ACTIONS.selectItem]: (state, action) => {
		return {
			...state,
			selectedItem: action.selectedItem,
			open: false,
		};
	},
	[MENU_ACTIONS.toggleHighlightItem]: (state, action) => {
		if (state.highlightedItems.includes(action.highlightItem)) {
			let index = state.highlightedItems.indexOf(action.highlightItem);
			return {
				...state,
				highlightedItems: [
					...state.highlightedItems.slice(0, index),
					...state.highlightedItems.slice(index + 1),
				],
			};
		} else {
			return {
				...state,
				highlightedItems: [...state.highlightedItems, action.highlightItem],
			};
		}
	},
};
