import React from 'react';
import { MultiContentWrapper } from './index.css';
import ContentRenderer from '../../ContentRenderer';

const MultiContent = ({ multiContent, contentCallback, renderCallback }) => {
	return (
		<MultiContentWrapper className="MultiContentWrapper">
			{multiContent.map((content, idx) => {
				return (
					<ContentRenderer
						key={idx}
						{...content}
						contentCallback={contentCallback}
						renderCallback={renderCallback}
					/>
				);
			})}
		</MultiContentWrapper>
	);
};

export default MultiContent;
