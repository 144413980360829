export const UI_ACTIONS = {
	SET_IS_READY: 'SET_IS_READY',
	SET_SHOW_POPUP: 'SET_SHOW_POPUP',
	SET_CURRENT_ACTION: 'SET_CURRENT_ACTION',
};

export const uiObjectReducer = {
	[UI_ACTIONS.SET_IS_READY]: (state, action) => {
		return {
			...state,
			isReady: action.isReady,
		};
	},
	[UI_ACTIONS.SET_SHOW_POPUP]: (state, action) => {
		return {
			...state,
			showPopup: action.showPopup,
		};
	},
	[UI_ACTIONS.SET_CURRENT_ACTION]: (state, action) => {
		return {
			...state,
			currentAction: action.currentAction,
		};
	},
};
