import styled from 'styled-components';

export const LeavingDisclaimerWrapper = styled.div`
	background: rgba(0, 0, 0, 0.4);
	height: 100vh;
	position: fixed;
	width: 100vw;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 9999999999;

	.outer {
		align-items: center;
		display: flex;
		height: 100%;
		width: 100vw;
	}

	.inner {
		background: #796e65;
		border-radius: 12px;
		margin: 0 auto;
		max-width: calc(100% - 2em);
		min-height: 200px;
		padding: 1.5em 1em;
		position: relative;
		width: 100%;
		@media screen and (min-width: 550px) {
			padding: 3.5em 2em;
		}
		@media screen and (min-width: 768px) {
			max-width: 700px;
			margin: 0 auto;
		}
	}

	.exit-text {
		color: #fff;
		font-size: 22px;
		font-weight: 100;
		text-align: center;

		@media screen and (min-width: 768px) {
			font-size: 30px;
			font-weight: bold;
		}
	}

	.main-text {
		color: #fff;
		font-size: 22px;
		font-weight: 100;
		margin-top: 1em;
		text-align: center;

		@media screen and (min-width: 768px) {
			font-size: 24px;
		}
	}

	.exit-buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 1em auto 0 auto;
		position: relative;
		width: calc(100% - 2em);
		@media screen and (min-width: 550px) {
			bottom: -22px;
			flex-wrap: nowrap;
			position: absolute;
		}
		@media screen and (min-width: 768px) {
			font-size: 18px;
		}
		button {
			margin: 0.25em 0.5em;
			@media screen and (min-width: 550px) {
				margin: 0 1em;
			}
		}
	}
`;
