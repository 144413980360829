import styled from 'styled-components';

export const AlertWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 20;

	.modal {
		position: absolute;
		top: 50%;
		left: 50%;
		background: #ccc;
		color: ${(props) => props.theme.alert.alertTextPrimary};
		width: 100%;
		max-width: 380px;
		text-align: center;
		border-radius: 6px 6px 0 0;
		transform: translate(-50%, -50%);
		box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.8);
		background-image: linear-gradient(
			to right,
			${(props) => props.theme.alert.alertBgTertiary},
			20%,
			${(props) => props.theme.alert.alertBgTertiaryHover}
		);
	}

	.modal-top {
		background: ${(props) => props.theme.alert.alertBgPrimary};
		height: 25px;
		border-radius: 6px 6px 0 0;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.modal-body-wrapper {
		border: 2px solid ${(props) => props.theme.alert.alertBorderPrimary};
		border-top: none;
	}

	.modal-body {
		background-size: 15%;
		background-repeat: no-repeat;
		padding: 20px;

		p {
			font-weight: bold;
		}
	}

	.modal-bottom {
		padding-bottom: 20px;
	}
`;
