import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  0% { opacity: 1; }
  99% { opacity: 0.01; width: 100%; height: 100%; }
  100% { opacity: 0; width: 0; height: 0; }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const BlackFadeWrapper = styled.div`
	position: absolute;
	top: 0px;
	left: 0;
	width: 100vw;
	height: 100%;
	background: black;
	z-index: 99998;

	&.visible {
		opacity: 1;
		animation: ${fadeIn} 0.6s;
	}

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}

	&.click-through {
		pointer-events: none;
		animation: ${fadeOut} 0.6s;
		animation-fill-mode: forwards;
	}
`;
