import React, { createContext, useReducer, useContext } from 'react';

export default function makeStore(userReducer, initialState, localKey) {
	const storeContext = createContext();
	const dispatchContext = createContext();

	let reducer = userReducer;
	if (localKey) {
		try {
			initialState = JSON.parse(localStorage.getItem(localKey)) || initialState;
		} catch (err) {} // eslint-disable-line
		reducer = (state, action) => {
			const newState = userReducer(state, action);
			try {
				localStorage.setItem(localKey, JSON.stringify(newState));
			} catch (err) {} // eslint-disable-line
			return newState;
		};
	}

	const StoreProvider = ({ children }) => {
		const [store, dispatch] = useReducer(reducer, initialState);

		return (
			<dispatchContext.Provider value={dispatch}>
				<storeContext.Provider value={store}>{children}</storeContext.Provider>
			</dispatchContext.Provider>
		);
	};

	function useStore() {
		return useContext(storeContext);
	}

	function useDispatch() {
		return useContext(dispatchContext);
	}

	return [StoreProvider, useDispatch, useStore];
}
