import React from 'react';

import MenuItemRenderer from '../MenuItemRenderer';

const Dropdown = ({ item, activeItems, childLevel = 0, dropdownCallback, customTypes = {} }) => {
	if (item.children) {
		return (
			<div className="dropdown-block">
				<MenuItemRenderer
					menuItemType={item.type}
					menuItemProps={{
						...item,
						active: activeItems.includes(item.action),
						className: `${item.className ? item.className : ''} dropdown dropdown-level-${childLevel}`,
					}}
					menuItemCallback={(cb) => {
						dropdownCallback({
							...cb,
							dropdownAction: 'dropdown',
							dropdownChildren: item.children,
						});
					}}
					customTypes={customTypes}
				/>

				<div className="dropdown-items">
					{activeItems.includes(item.action)
						? item.children.map((item, index) => {
								return (
									<Dropdown
										key={index}
										item={item}
										childLevel={childLevel + 1}
										dropdownCallback={dropdownCallback}
										activeItems={activeItems}
									/>
								);
						  })
						: null}
				</div>
			</div>
		);
	} else {
		return (
			<>
				<MenuItemRenderer
					menuItemType={item.type}
					menuItemProps={{
						...item,
						active: activeItems.includes(item.action),
						className: `${item.className ? item.className : ''} dropdown-level-${childLevel}`,
					}}
					menuItemCallback={(cb) => {
						dropdownCallback({
							...cb,
							dropdownAction: 'select',
						});
					}}
					customTypes={customTypes}
				/>
			</>
		);
	}
};

export default Dropdown;
