import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Button from '../Widgets/Button';
import ButtonReverse from '../Widgets/ButtonReverse';
import { AlertWrapper } from './index.css';

export const Alert = ({ message, iosMessage, show, closeText, closeCallback, buttons, menuBg, logoSymbol }) => {
	const [visible, setVisible] = useState(show);

	const isApple = navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1;

	return (
		<>
			{visible && (
				<motion.div
					className="AlertMotion"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					style={{
						width: '100%',
						height: '100%',
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 100000,
					}}
				>
					<AlertWrapper className="AlertWrapper">
						<div className="modal">
							<div className="modal-top" menuBg={menuBg} />
							<div className="modal-body-wrapper">
								<div className="modal-body" logoSymbol={logoSymbol}>
									{isApple ? <p>{iosMessage}</p> : <p>{message}</p>}
								</div>
								<div className="modal-bottom">
									<ButtonReverse
										text={closeText}
										handleSubmit={() => {
											setVisible(false);
											if (closeCallback) closeCallback();
										}}
									/>
									{buttons &&
										buttons.map(({ text, callback }, idx) => (
											<Button key={idx} text={text} handleSubmit={callback} />
										))}
								</div>
							</div>
						</div>
					</AlertWrapper>
				</motion.div>
			)}
		</>
	);
};

export default Alert;
