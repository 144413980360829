import { useReducer } from 'react';
import { createReducerFromObject } from '../../../utils';
import { defaultMenuInitialState, defaultMenuObjectReducer, MENU_ACTIONS } from './menuReducer';

export default function useMenu({ initialState = {}, reducerActions = {} } = {}) {
	const reducer = createReducerFromObject({
		...defaultMenuObjectReducer,
		...reducerActions,
	});

	const [{ open, selectedItem, highlightedItems, menuItems }, dispatch] = useReducer(reducer, {
		...defaultMenuInitialState,
		...initialState,
	});

	const setMenuItems = (items) => dispatch({ type: MENU_ACTIONS.setMenuItems, menuItems: items });
	const toggleMenu = () => dispatch({ type: MENU_ACTIONS.toggle });
	const openMenu = () => dispatch({ type: MENU_ACTIONS.open });
	const closeMenu = () => dispatch({ type: MENU_ACTIONS.close });
	const selectItem = (item) => dispatch({ type: MENU_ACTIONS.selectItem, selectedItem: item });
	const toggleHighlightItem = (item) =>
		dispatch({
			type: MENU_ACTIONS.toggleHighlightItem,
			highlightItem: item,
		});

	return {
		menuItems,
		setMenuItems,
		open,
		toggleMenu,
		openMenu,
		closeMenu,
		selectItem,
		selectedItem,
		highlightedItems,
		toggleHighlightItem,
	};
}
